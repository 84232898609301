<template>
  <div class="container mx-auto py-16 ">
    <h1 class="text-4xl mb-4 font-merri p-6 pt-0 text-center ">
      Terms &amp; Conditions
    </h1>
    <div class="w-full sm:w-2/3 mx-auto">
      <p>
        It’s not cool when companies make you wade through endless T&amp;C’s and
        use complicated words. Fellow cats, we’ve all got better things to be
        doing with our lives. We’ve summarised a couple of key points you might
        be looking for:
      </p>

      <div class="flex mb-4">
        <div class="mr-4">1.</div>
        <div>
          <strong> Cancel anytime</strong> Means exactly that. Just adjust or
          cancel anytime before your Dispatch date (fully visible when you log
          in to your account)
        </div>
      </div>
      <div class="flex mb-4">
        <div class="mr-4">2.</div>
        <div>
          Payment is taken on or around the Dispatch date, if payment cannot be
          taken for whatever reason we have the right to withhold dispatch of
          any goods (you gotta pay for it tiger!)
        </div>
      </div>

      <p class="text-center font-semibold mt-16 mb-16">
        Now for the long-winded bit the legal begals made us put in:
      </p>
      <p>
        This whole document forms the Terms and Conditions of using our website
        (the "Website"), accessing our logged in pages and purchasing any
        products from this website.
      </p>
      <div class="text-xl font-semibold mb-8">
        Introduction
      </div>
      <p>
        These Terms and Conditions govern you – a customer (potential, past or
        current) and / or a viewer of the website (“you”, “your”) relationship
        with Valtrus Ltd (trading as thecoolcatclub.com), a company incorporated
        in England, whose registered office for correspondence is at 11 Barlow
        Road, Wilmslow, Cheshire, SK9 4BE registered company number 11894945,
        (“thecoolcatclub.com”, “Valtrus”, “Us”, “We”, “Our”), your Logged In
        Pages (the functional areas of the Website, pages at thecoolcatclub.com,
        where you can edit information and add information bespoke to you), all
        orders and / or subscriptions you make for the Cat/pet products
        advertised for sale by us (the “Products”) via the website, and all
        Communications with you (Communications) (including but not limited to
        emails from us to you, printed material that will accompany your Orders,
        labelling particulars on the Products)
      </p>
      <p>
        You should read these Terms and Conditions carefully before using the
        Website.
      </p>
      <p>
        By using this site you signify your acceptance of our terms and
        conditions in return for which we will grant you access to our Website
      </p>
      <p>
        If you do not agree to these Terms and Conditions please exit our
        website immediately, do not set up an account, login to and / or enter
        information into the Logged In Pages and do not order any product from
        thecoolcatclub.com
      </p>
      <p>
        No changes to these Terms and Conditions are valid or have any effect
        unless agreed by us in writing. We reserve the right to modify our Terms
        and Conditions. From time to time we will modify the Terms and
        Conditions and our updated terms will be displayed on the Website and by
        continuing to use and access the Website following such changes, you
        agree to be bound by any variation made by us. It is your responsibility
        to check these Terms and Conditions from time to time to verify such
        variations.The Terms and Conditions applicable to your access and use of
        the Website are the version that is displayed on the website at each
        date you access the Website. If at any time you do not wish to accept
        the Terms and Conditions you must not continue to use the website. Your
        continued use of the website, and your continued subscription after we
        make any changes constitutes your acceptance of the changes. If you do
        not agree to the changes, you must cancel your subscription. Details of
        how to cancel can be found below
      </p>
      <p>
        Please also refer to our
        <router-link :to="{ name: 'faqs' }">FAQs</router-link> on the website
        for additional information
      </p>
      <p>
        This Website and our Communications are intended for access in the UK.
        Any users in a jurisdiction where the site’s publication or availability
        is prohibited must not access the site. Those who access the site do so
        on their own initiative and are responsible for compliance with local
        laws where they are applicable
      </p>
      <p>
        We seek to make the Website as accessible as possible. If you have any
        difficulties using the Website, please contact us at
        team@thecoolcatclub.com Your use of the Website means that you must also
        comply with our Privacy policy, our Cookie policy and our Online terms
        and conditions for the supply of goods, where applicable
      </p>
      <div class="text-xl font-semibold mb-8">
        Electronic communication
      </div>
      <p>
        When using our website functions (including the consultation feature
        where you enter details about your pet), using the Logged In pages or
        emailing us you are communicating with us electronically. You consent to
        receive communications from us electronically. All information,
        disclosures, notices and agreements we communicate to you via email or
        via the website are considered in writing
      </p>
      <p>
        By giving us your email address (by voluntarily creating a user account
        with us, and / or purchasing products through us) you consent to
        receiving electronic communication directly from us. This communication
        can be directly related to your purchase or direct marketing from us
      </p>
      <div class="text-xl font-semibold mb-8">
        Registration and account management
      </div>
      <p>
        To purchase any products and to take part in our consultation, we
        require you to register with us using the process defined on our
        Website. Registering with us generates a User Account (“User Account”,
        “Account”, a unique username with associated information) for you and
        your pet(s). This gives you access to the Logged In Pages, and means you
        are able to purchase products from us
      </p>
      <p>
        To register with us, you must be 18 years of age or over on the date of
        registering
      </p>
      <p>
        You must ensure that the details you provide us, via the Logged in Pages
        or any other communication (including email) are accurate and complete
      </p>
      <p>
        You must promptly inform us of any changes to the information that you
        provided us with – you can do this quickly and easily by accessing the
        Logged In Pages. This includes your payment details. If we are unable to
        take payment from your card we are unable to process your order
      </p>
      <p>
        If you require the changes to take effect prior to your next order
        please do this greater than 24 hours before your order is to be packed
        and shipped, as displayed on your logged-in pages, this date for changes
        is displayed as the Dispatch Date.
      </p>
      <p>
        All personal and payment details are governed by our Privacy Policy
      </p>
      <p>
        Accounts are non-transferrable and you cannot assign, transfer, trade or
        sell your Account with thecoolcatclub.com to any other party
      </p>
      <p>
        You can access the specific details regarding your subscription by
        logging into your User Account and clicking onto the relevant section of
        the Website
      </p>
      <p>
        You agree not to impersonate any other person whilst using our website.
        You also agree not to conduct yourself in an offensive, illegal, immoral
        or offensive manner whilst using our website
      </p>
      <p>
        You may not use our site for illegal purposes or in any way that may
        damage our name or reputation
      </p>
      <p>
        We may prevent or suspend your access to the Website if you do not
        comply with any part of these Terms and Conditions, any terms or
        policies to which they refer or any applicable law
      </p>
      <div class="text-xl font-semibold mb-8">
        Password and security
      </div>
      <p>
        When you register with us on our Website you will be asked to create a
        password which is associated with your User Account. It is your
        responsibility to choose a secure password and keep this safe
      </p>
      <p>
        You are responsible for all purchases made on your thecoolcatclub.com
        Account
      </p>
      <p>
        You are responsible for all actions and changes to your Account in the
        Logged In Pages of thecoolcatclub.com (including but not limited to
        change of Pet details, postal address and delivery instructions)
      </p>
      <p>
        You must notify us immediately if you suspect or know that anyone has
        gained unauthorised access to your thecoolcatclub.com Account
      </p>
      <p>
        We are only able to register one owner per account, and for security
        purposes we may ask for confirmation of your details if you contact us
        to discuss your account
      </p>
      <p>You are responsible for keeping your account details confidential</p>
      <div class="text-xl font-semibold mb-8">
        Orders and subscription
      </div>
      <p>
        You must have an Account to purchase any products or make any orders
        with us
      </p>
      <p>
        You must also have correct, accurate and valid card details entered on
        your User Account
      </p>
      <p>
        When you register an account and select a delivery date with Us, you
        will automatically be subscribed to a regular order of products.
      </p>
      <p>
        The subscription product is based on criteria specified about your
        pet(s) by you in the Logged In Pages
      </p>
      <p>
        The total price paid by you for the is the price of the specific feeding
        plan in each specific order calculated on the day your order is
        processed, plus VAT where appropriate. We reserve the right to vary the
        contents of your order without notice and at our sole discretion
      </p>
      <p>
        You can manage your future orders with us through your Logged In Pages
      </p>
      <p>
        Your subscription will remain in place unless cancelled (through your
        Logged In Pages)
      </p>
      <p>
        Any changes you make to your online profile through the Logged In Pages
        may change the price of your order, and we will display this price to
        you once you have submitted these changes
      </p>
      <p>
        The charge for the total price will be charged to the payment card we
        hold on your account for you at the time of the Order Processing for
        each specific order.
      </p>
      <p>
        Following your 2 week trial, you’ll be billed on day 14 for your monthly
        subscription. Following this you’ll be billed every 28 days thereafter
        until at any stage the account is paused or cancelled
      </p>
      <p>
        Your subscription to any Feeding Plan will remain in effect until it is
        cancelled by you. Details on how to cancel can be found in the
        <router-link :to="{ name: 'faqs' }">FAQs</router-link>
      </p>
      <p>
        If you cancel a subscription and reactivate at a different time the
        price for the subscription may be a different price than the price in
        effect for the subscription at the time of cancelling the original
        subscription
      </p>
      <div class="text-xl font-semibold mb-8">
        Deliveries
      </div>

      <p>
        Products ordered from us can be delivered to addresses within the UK
        (excluding Northern Ireland). This excludes PO Boxes
      </p>
      <p>Products will be delivered via our third-party courier</p>
      <p>Proof of delivery is only obtained under some circumstances</p>
      <p>
        Deliveries run on a regular schedule that is visible to you in the
        logged-in pages for your account. This includes the price of the next
        order to be shipped and the dispatch dates for this
      </p>
      <p>
        Actual delivery times may vary based on your location, our stock
        availability, when you finalise your information on the logged in pages,
        and circumstances impacting delivery out of our control (including
        public holidays and force majeure scenarios)
      </p>
      <p>You will not hold us responsible for any delays out of our control</p>
      <p>
        You must report missing or undelivered deliveries by emailing us at
        team@thecoolcatclub.com
      </p>
      <p>
        Please ensure your address, contact details and leave safe information
        is kept up to date as referred to in the Registration and Account
        Management section of these Terms & Conditions
      </p>
      <div class="text-xl font-semibold mb-8">
        Returns
      </div>

      <p>
        In the rare event you would like to exchange or refund, whilst this is
        possible it can be a expensive exercise and we highly recommend it's
        better to hold onto the food and reintroduce a few weeks later. So very
        often cats will decide they like a flavour when tried again at a
        separate time!
      </p>
      <p>
        If a refund/exchange is required it must be returned to us using a
        tracked service at your cost. Once it arrives here we can process a
        refund. Any refund or exchange will be with a deduction for our courier
        delivery and packaging costs of £8, incurred sending the food order out.
      </p>
      <p>
        If a product is to be exchanged an additional fee of £8 will be deducted
        for sending the new food out
      </p>

      <div class="text-xl font-semibold mb-8">
        Cancellation
      </div>
      <p>
        You can cancel your subscription at any time, through your logged in
        pages. You can read more about how to do this in the FAQ. The Dispatch
        Date for upcoming orders is visible on your logged in pages. Your
        subscription must be cancelled by midnight before this date. Orders will
        still be charged and delivered if your notification of cancellation is
        after this date.
      </p>
      <div class="text-xl font-semibold mb-8">
        Termination or refusal by us
      </div>
      <p>
        We reserve the right to refuse or cancel subscription orders at our sole
        discretion. If we do this, you will only be charged for orders that have
        been shipped to you
      </p>
      <div class="text-xl font-semibold mb-8">
        Termination or refusal by us
      </div>
      <p>
        We reserve the right to refuse or cancel subscription orders at our sole
        discretion. If we do this, you will only be charged for orders that have
        been shipped to you
      </p>
      <div class="text-xl font-semibold mb-8">
        Member and introductory offers and discounts
      </div>
      <p>
        We may offer new and/or existing customers special offers from time to
        time
      </p>
      <p>
        Where we make such offers, Introductory offers are only available to new
        users of the website. Previously registered users do not qualify for an
        additional offer, unless expressly stated. “Previously registered users”
        refers to any combination of: a user with a user account at
        thecoolcatclub.com, a credit or debit card, a credit or debit card
        holder, a delivery address, a cardholder address, or a household, who
        has previously made use of the thecoolcatclub.com service
      </p>
      <p>
        Offers are limited to one individual cat, unless otherwise explicitly
        stated
      </p>
      <p>
        We may cancel any special offer, introductory offer or discount at any
        time without notice
      </p>
      <p>
        At our sole discretion, we may restrict who may enter into offers for
        any reason
      </p>
      <p>
        Promotional offers and discounts are available for limited time periods
        only
      </p>
      <p>Delivery charges may apply to trial orders</p>
      <p>
        The referral scheme allows existing thecoolcatclub.com customers to
        share their unique referral codes with their friends to enable them to
        trial the thecoolcatclub.com service at a discount. The referral codes
        can only be used by new customers who have not previously had a
        thecoolcatclub.com account or delivery (therefore previously registered
        users cannot use a referral code). Existing thecoolcatclub.com customers
        cannot use referral codes, and customers cannot use their own unique
        referral codes on their own account
      </p>
      <p>
        We reserve the right to put any user account(s) or cat profile(s) on
        hold and prevent outgoing deliveries, and/or remove any referral credit
        from any user account(s) where we become aware of or detect any abuse or
        unfair treatment of an offer or referral code(s) and associated
        Reward(s)
      </p>
      <p>
        When sharing your referral code you may not mimic or misrepresent
        thecoolcatclub.com or otherwise mislead people to believe that they are
        dealing with thecoolcatclub.com or affiliates. We require you to sign up
        with a user account at theccolcatclub.com in order to redeem any offer.
        This process is detailed in the “Registration and Accounts Management"
        section, and specifically this registration process includes you
        registering a valid credit or debit card, this includes registering with
        free and discounted offers
      </p>
      <p>
        Except where otherwise stated, discounts and credits cannot be used in
        conjunction with any other offers
      </p>
      <p>
        Once an account has been inactive for 12 months (defined as there being
        no deliveries in the preceding 12 months) we reserve the right to remove
        referral credit whether it has been claimed or not. Any referral credit
        (whether claimed or not) on an account which is cancelled is immediately
        removed
      </p>
      <p>
        Purchase of our products is only via our website, and requires a
        registered account with valid payment and delivery details to redeem a
        free or discounted offer, or to redeem an account credit
      </p>
      <p>Account credits and offers cannot be redeemed for cash</p>
      <p>
        After any discounted offer you will be charged the full price for
        subsequent orders until such time as another applicable and valid
        discounted offer is applied, or you cancel active subscriptions and
        orders with us
      </p>
      <p>These terms are subject to change at any time</p>
      <div class="text-xl font-semibold mb-8">
        Copyright and intellectual property
      </div>
      <p>
        The Website and all intellectual property rights in it including but not
        limited to any content are owned by us all times, unless licensed or
        borrowed property. Those works are protected by copyright laws and
        treaties around the world. All such rights are reserved
      </p>
      <p>
        This content includes any text, image, video, audio or other multimedia
        content, software or other information or material submitted to or on
        the Site. Intellectual property includes logos, trademarks, trade names,
        domain names, database rights patents, design rights, copyright and all
        other intellectual property rights of any kind whether or not they are
        registered or unregistered (anywhere in the world) derived from the
        design or function of the Website or any Communications
      </p>
      <p>
        Through use of the Website, or as a recipient of the Communications, You
        automatically agree to respect these copyright and intellectual property
        rights
      </p>
      <p>
        You may print off one copy, and may download extracts, of any page(s)
        from our site for your personal use and you may draw the attention of
        others within your organisation to content posted on our site.
      </p>
      <p>
        You may not copy, download, transmit, reproduce, print or reproduce in
        any means any materials contained within the Website for commercial
        purposes unless expressly permitted by thecoolcatclub.com
      </p>
      <p>
        You must not modify the paper or digital copies of any materials you
        have printed off or downloaded in any way, and you must not use any
        illustrations, photographs, video or audio sequences or any graphics
        separately from any accompanying text
      </p>
      <p>
        Our status as the authors of content on our site must always be
        acknowledged
      </p>
      <p>
        If you print off, copy or download any part of our site in breach of
        these terms of use, your right to use our site will cease immediately
        and you must, at our option, return or destroy any copies of the
        materials you have made
      </p>
      <p>
        Nothing in these Terms and Conditions grants you any legal rights in the
        Website other than as necessary to enable you to access the Website. You
        agree not to adjust to try to circumvent or delete any notices contained
        on the Website (including any intellectual property notices) and in
        particular any digital rights or other security technology embedded or
        contained within the site.
      </p>
      <p>
        From time to time this website may also include links to external
        websites (outside the thecoolcatclub.com domain). Any such hyperlinks or
        references are provided for your convenience only. We have no control
        over third party websites and accept no legal responsibility for any
        content, material or information contained in them. The display of any
        hyperlink and reference to any third party website does not signify our
        endorsement of the websites or associated companies, their products or
        services. Your use of a third party site may be governed by the terms
        and conditions of that third party site
      </p>
      <p>
        You may link to our home page, provided you do so in a way that is fair
        and legal and does not damage our reputation or take advantage of it.
      </p>
      <p>
        You must not establish a link in such a way as to suggest any form of
        association, approval or endorsement on our part where none exists.
      </p>
      <p>
        Our site must not be framed on any other site, nor may you create a link
        to any part of our site other than the home page.
      </p>
      <p>
        We reserve the right to request that you remove any links to our website
        that you create and publish
      </p>
      <div class="text-xl font-semibold mb-8">
        No warranty
      </div>
      <p>
        This Website and our Communications, and their content, are provided as
        is
      </p>
      <p>
        We accept no liability for the content of and functions contained on the
        Website or in our Communications
      </p>
      <p>
        We cannot promise that the Website will be fit or suitable for any
        purpose. Any reliance that you may place on the information on this
        Website is at your own risk
      </p>
      <p>
        While we try to make sure that the Website is available for your use, we
        do not promise that the Website is available at all times and we make no
        warranty that the website will operate uninterrupted or error-free
      </p>
      <p>
        We may suspend or terminate operation of the Website at any time as we
        see fit
      </p>
      <p>
        We do not warrant that the site is compatible with all computer or
        mobile device software and equipment
      </p>
      <p>
        While we take internet security seriously and try to make sure that the
        Website is accurate, up-to-date and free from bugs we cannot warrant
        that the Website and its server is free of errors, viruses, worms,
        Trojan Horses or any other malicious software. We shall not be liable
        for any damage you may suffer as a result of such destructive and
        unintended features.
      </p>
      <p>
        We will not be deemed to be in breach of contract or of these Terms and
        Conditions as a result of any failure to perform our obligations or any
        delay in our performance if that failure or delay is due to any
        circumstance beyond our reasonable control
      </p>
      <p>
        We warrant that the products will be of satisfactory quality and fit for
        purpose
      </p>
      <p>
        This warranty is in lieu of all other warranties, expressed or implied
      </p>
      <div class="text-xl font-semibold mb-8">
        Limitation of liability
      </div>
      <p>
        You acknowledge that your use of this site and its content is at your
        own risk
      </p>
      <p>
        You acknowledge that the feeding guides provided by us is intended as a
        guide only. It is your responsibility as a pet owner to monitor your
        pet's weight and condition, and to let us know any changes in either
        weight or condition. If you have any concerns about your pet's weight
        and condition, please consult your vet
      </p>
      <p>
        Except for liability which we cannot limit, restrict or exclude by law
        we shall have no liability to you or any third party for any direct,
        indirect or consequential losses or damages (including loss of profits),
        or any other damages of any kind whether that were not foreseeable to
        both you and us at the point of sale or use of our website
      </p>
      <p>
        We shall have no liability to you for any breach of these Terms and
        Conditions caused by any event or circumstance beyond our reasonable
        control including, but not limited to, strikes, lock-outs or other
        industrial disputes; breakdown of systems or network access; or flood,
        fire, explosion or accident
      </p>
      <p>
        Customers should be aware that an order from thecolcatclub.com can be
        heavy, and so extra care should be taken when accepting delivery and
        unpacking the contents of the order, doing so directly from the box at
        ground level if required
      </p>
      <div class="text-xl font-semibold mb-8">
        Validity of provisions and remedies
      </div>
      <p>
        If any provisions of these Terms and Conditions is or becomes invalid or
        contravenes applicable regulations then the remaining provisions will
        not be affected
      </p>
      <div class="text-xl font-semibold mb-8">
        Waiver and breach
      </div>
      <p>
        No waiver by us or breach of the Terms and Conditions shall constitute a
        waiver of any other form
      </p>
      <p>
        No failure by us to exercise any remedy shall constitute a waiver of the
        right subsequently to exercise that or any other remedy
      </p>
      <div class="text-xl font-semibold mb-8">
        Web links and advertising
      </div>
      <p>
        Links to our Website from other Websites or Publications (included but
        not limited to magazines, newspapers, newsletters and flyers) (other
        than sites owned by Us or publications commissioned by us) are for
        information only. They have not been reviewed by us and as such we have
        no responsibility for the content of such Websites or Publications and
        accept no liability for any losses whatsoever than may be incurred as a
        result
      </p>
      <p class="mt-12">
        Feed registration number GB 703 E3752
      </p>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: 'The Cat Cool Cat Clubs Month Subscription T & C’s',
    meta: [
      {
        name: 'description',
        content:
          'Read more about the Cool Cat Club’s Terms & Conditions for their Monthly Premium Cat Food Subscription Service.'
      }
    ]
  }
};
</script>

<style lang="css" scoped></style>
